import * as React from "react";
import SEO from "../components/Layout/SEO";

// markup
const NotFoundPage = () => {
  return (
    <>
      <SEO title="Page is not found" />
      <div className="dark:text-white mt-5 py-5 px-5 md:py-20 relative z-10">
        <h1 className="text-center text-7xl font-bold">404</h1>
        <p className="text-center text-xl mb-10 ">
          You just hit a page that doesn’t exist... the sadness.
        </p>
        <video
          muted
          loop
          autoPlay
          className="block mx-auto rounded shadow-xl brightness-100 filter"
          width="600"
        >
          <source src="/images/404.mp4" type="video/mp4" />
        </video>
        <span className="text-sm text-center text-gray-400 block mt-3">
          When developers try to fix a bug in production
        </span>
      </div>
    </>
  );
};

export default NotFoundPage;
